$hover-active-links-color: #f2f2f2;

@mixin internal-menu {
  ul {
    padding: 0;
    margin: 0;
  }

  > ul {
    border-bottom: 1px solid $gray-light;

    > li {
      min-height: 50px;
      list-style: none;
      list-style-image: none;
      padding: 0;
      > a {
        height: 50px;
        width: 100%;
        vertical-align: middle;
        font-weight: 700;
        color: $brand-danger;
        padding: 0 15px;
        text-transform: uppercase;
        border-top: 1px solid $gray-light;
        text-decoration: none;
      }

      &.expanded {
        > a.active,
        > a.active-trail {
          border-bottom: 1px solid $gray-light;
          padding-bottom: 4px;
        }
      }
      > ul {
        background-color: $hover-active-links-color;

        > li {
          min-height: 30px;

          > a {
            font-weight: 700;
          }

          a {
            padding: 5px 15px 5px 35px;
            color: $gray-darker;

            &.active,
            &:hover {
              background-color: #d8d8d8;
            }
          }

          > ul {
            > li {
              a {
                padding-left: 60px;
                font-weight: 600;
              }

              > ul > li > a {
                padding-left: 80px;
              }
            }
          }
        }
      }
    }

    li {
      a {
        width: 100%;
        display: table;
        span {
          display: table-cell;
          vertical-align: middle;
        }

        &.active,
        &.active-trail {
          background-color: $hover-active-links-color;
        }
      }
    }
  }
}

// .region-sidebar-first {
//   .block-menu-block {
//     > .menu-block-wrapper {
//       @include internal-menu;

//       > ul {
//         > li {
//           > a {
//             &:hover {
//               background-color: $hover-active-links-color;
//             }
//           }
//         }
//       }
//     }
//   }
// }
.l-sidebar-first {
  .block-menu-menu-research-and-toolkits-menu {
    .block-content {
      @include internal-menu;

      > ul {
        > li {
          > a {
            &:hover {
              background-color: $hover-active-links-color;
            }
          }
        }
      }
    }
  }
}

///*
// * @file
// * CSS for the responsive menu toggle checkbox / button.
// */
//
///**
//  * Menu toggle button
//  */
//.menu-toggle-button {
//  position: relative;
//  display: inline-block;
//  text-indent: 28px;
//  white-space: nowrap;
//  overflow: hidden;
//  cursor: pointer;
//  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//}
//
//@media (min-width: $screen-sm-max) {
//  // @media (min-width: 48em) {
//  /* 768px @ 16px font size */
//  /* hide the button in desktop view */
//  .menu-toggle-button,
//  .menu-toggle-state {
//    display: none;
//  }
//}
//.menu-toggle-button-text {
//  vertical-align: middle;
//  padding: 0 0.5em;
//}
//
///**
//   * Hamburger icon.
//   */
//.menu-toggle-button-icon,
//.menu-toggle-button-icon:before,
//.menu-toggle-button-icon:after {
//  position: absolute;
//  top: 50%;
//  left: 2px; /* LTR */
//  height: 2px;
//  width: 24px;
//  background: #bbb;
//  -webkit-transition: all 0.25s;
//  transition: all 0.25s;
//}
//[dir="rtl"] .menu-toggle-button-icon {
//  right: 2px;
//}
//.menu-toggle-button-icon:before {
//  content: "";
//  top: -7px;
//  left: 0; /* LTR */
//}
//.menu-toggle-button-icon:after {
//  content: "";
//  top: 7px;
//  left: 0; /* LTR */
//}
//[dir="rtl"] .menu-toggle-button-icon:before,
//[dir="rtl"] .menu-toggle-button-icon:after {
//  right: 0;
//}
//
///**
//   * Close icon.
//   */
//.menu-toggle-state:checked ~ .menu-toggle-button .menu-toggle-button-icon {
//  height: 0;
//  background: transparent;
//}
//.menu-toggle-state:checked
//  ~ .menu-toggle-button
//  .menu-toggle-button-icon:before {
//  top: 0;
//  -webkit-transform: rotate(-45deg);
//  transform: rotate(-45deg);
//}
//.menu-toggle-state:checked
//  ~ .menu-toggle-button
//  .menu-toggle-button-icon:after {
//  top: 0;
//  -webkit-transform: rotate(45deg);
//  transform: rotate(45deg);
//}
//
///**
//   * Menu state checkbox.
//   */
//.menu-toggle-state {
//  position: absolute;
//  width: 1px;
//  height: 1px;
//  margin: -1px;
//  border: 0;
//  padding: 0;
//  overflow: hidden;
//  clip: rect(1px, 1px, 1px, 1px);
//}
//.menu-toggle-state ~ .menu {
//  display: none;
//}
///* Hide the menu in mobile view. */
//.menu-toggle-state:not(:checked) ~ .menu {
//  display: none;
//}
//.menu-toggle-state:checked ~ .menu {
//  display: block;
//}
//@media (min-width: $screen-sm-max) {
//  /* 768px @ 16px font size. */
//  /* Always show the menu in desktop view. */
//  .menu-toggle-state:not(:checked) ~ .menu {
//    display: block;
//  }
//}
//
//#navbar {
//  @media (max-width: $screen-sm-max) {
//    > div.container {
//      display: inline;
//    }
//  }
//}
//.open-search {
//  span {
//    display: none;
//  }
//}
//@media (max-width: $screen-md-max) {
//  .open-search {
//    span {
//      display: block;
//    }
//  }
//}
//@media (max-width: 991.98px) {
//  #desktop.open-search {
//    span {
//      display: none;
//    }
//  }
//  #phone.open-search {
//    span {
//      display: table-cell;
//      vertical-align: middle;
//    }
//  }
//  .menu-toggle-state:checked ~ .menu {
//    position: relative;
//    ul {
//      position: absolute;
//      background: $gray-lighter;
//      z-index: 2;
//      padding-left: 1em;
//      li {
//        border-bottom: 1px solid #ccc;
//      }
//    }
//  }
//}
//@media (min-width: 992px) {
//  div.menu-tree {
//    display: flex !important;
//  }
//  #desktop.open-search {
//    display: table;
//    margin: 0 0 0 0.5em;
//    span {
//      display: table-cell;
//      vertical-align: middle;
//      i {
//        padding: 8px;
//        color: gray;
//        cursor: pointer;
//        &:hover {
//          color: $navbar-default-link-hover-color;
//        }
//      }
//    }
//  }
//  #phone.open-search {
//    span {
//      display: none;
//    }
//  }
//}
//.navbar-collapse > div.block-content {
//  label.menu-toggle-button {
//    width: 150px;
//  }
//  #phone.open-search {
//    display: inline-block;
//  }
//}
//
//// @include media-breakpoint-up(xs) {
////   .menu-toggle-button {
////     width: 135px;
////   }
//// }
//@media (max-width: 575.98px) {
//  .menu-toggle-button {
//    width: 135px !important;
//  }
//}
//
//@media (max-width: 374px) {
//  .menu-toggle-button {
//    width: 87px !important;
//  }
//}
//
//// search form
////
//@media (max-width: $screen-sm-max) {
//  .navbar-brand img {
//    max-height: 60px;
//  }
//}
//.search-block-form {
//  opacity: 0;
//  height: 0;
//  margin: 0 auto;
//  -webkit-transition: all 0.25s ease-in-out;
//  -moz-transition: all 0.25s ease-in-out;
//  -o-transition: all 0.25s ease-in-out;
//  transition: all 0.25s ease-in-out;
//  div {
//    .form-item {
//      margin-top: 12px;
//    }
//    .form-actions {
//      margin-top: 12px;
//    }
//  }
//}
//.search-block-form.show {
//  display: block;
//  opacity: 1;
//  height: 63px;
//  margin: 0 auto;
//  -webkit-transition: all 0.25s ease-in-out;
//  -moz-transition: all 0.25s ease-in-out;
//  -o-transition: all 0.25s ease-in-out;
//  transition: all 0.25s ease-in-out;
//}
//
//@media (max-width: 991.98px) {
//  ul.menu-dropdown {
//    position: absolute;
//    margin: 0;
//    -webkit-box-shadow: 0 4px 6px 0 #d0d3d6;
//    box-shadow: 0 4px 6px 0 #d0d3d6;
//  }
//}
//@media (min-width: 992px) {
//  .js .menu-dropdown a,
//  .js .menu-dropdown a:hover,
//  .js .menu-dropdown a:focus,
//  .js .menu-dropdown a:active {
//    padding-right: 0.5rem;
//  }
//  .menu-tree li {
//    margin: 0 0 0 0.5em;
//  }
//}
//
//// Custom Header
////.nice-menu-search .navbar-toggler {
////  padding: 3px 10px;
////  border: 1px solid #999;
////  border-radius: 5px;
////}
//@media (max-width: 991.98px) {
//  form.search-form {
//    width: 100%;
//  }
//  .header-block-menu .menu-dropdown{
//    position: relative;
//    box-shadow: unset;
//  }
//  .header-block-menu .block-system-main-menu{
//
//  }
//}
//
//@media (max-width: 768.98px) {
//  .header-block-menu .menu-dropdown{
//    width: 100%;
//  }
//}
//

/**
 * @file
 * Header region styling.
 */
@media (min-width: 34em) {
  .l-header-inner {
    min-height: 6.125rem;
  }
}

.l-header a {
  transition: background-color 0.25s ease-out;
}

.header-identity-wrapper:after {
  /* Clearfix */
  content: '';
  display: table;
  clear: both;
}
.header-identity-wrapper strong {
  font-weight: inherit;
}

@media (min-width: 48em) {
  .header-identity-wrapper {
    display: block;
    float: left;
  }

  [dir="rtl"] .header-identity-wrapper {
    float: right;
  }
}

.header-site-name-wrapper {
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
}

@media (min-width: 34em) {
  .header-site-name-wrapper {
    float: left;
  }

  [dir="rtl"] .header-site-name-wrapper {
    float: right;
  }
}

.header-logo-wrapper {
  display: block;
  float: left;
}

[dir="rtl"] .header-logo-wrapper {
  float: right;
}

/* Makes sure images that are tall logos don't pop out of container */
.header-logo-tall img {
  width: auto;
  height: 100%;
}

.header-site-name-link {
  display: block;
  margin: 0;
}

.header-site-slogan {
  margin: 0;
}

.l-header .block-system-header .block-content:after {
  /* Clearfix header block */
  content: "";
  display: table;
  clear: both;
}

/**
 * Menus in header area
 */
.header-menu {
  clear: both;
}

.header-menu:after {
  /* Clearfix header menu */
  content: "";
  display: table;
  clear: both;
}

.header-menu > .links {
  /* Center menu at mobile */
  display: inline-block;
}

@media (min-width: 48em) {
  .header-menu {
    float: right;
    clear: none;
  }

  [dir="rtl"] .header-menu {
    float: left;
    clear: none;
  }
}

@media (min-width: 34em) {
  .l-header .block-system-main-menu {
    clear: both;
  }
}

.l-header .menu,
.l-header .menu li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.l-header .menu:after {
  /* Clearfix menus */
  content: '';
  display: table;
  clear: both;
}

.l-header .menu > li,
.header-menu li {
  float: left;
}

[dir="rtl"] .l-header .menu > li,
[dir="rtl"] .header-menu li {
  float: right;
}

.l-header .menu a,
.header-menu a {
  display: block;
  padding: 1rem 1.25rem;
}
.l-header .menu-toggle-button {
  //margin-left: .9375rem;
  padding: .5rem 0;

}
.l-header .menu-toggle-button-text {
  color: $purple;
}

.l-header .menu-toggle-button-icon,
.l-header .menu-toggle-button-icon:after,
.l-header .menu-toggle-button-icon:before,
{
  background-color: $purple;
}

@media (min-width: 34em) {
  /* Specific selector to avoid contextual links */
  .l-header .menu a,
  .header-menu a {
    padding: 1rem 1.25rem;
  }
}

@media (min-width: 48em) {
  .header-menu a {
    /* Attempt at lining up baseline of site name and header menu */
    padding-top: 1.9375rem;
    padding-bottom: 1.0625rem;
    line-height: 1.25rem;
  }
}

@media (min-width: 62em) {
  .header-menu a {
    /* Attempt at lining up baseline of site name and header menu */
    padding-top: 2rem;
    padding-bottom: 1rem;
    line-height: 1.25rem;
  }
}

.header-menu .links,
.header-menu li {
  margin: 0;
  padding: 0;
}

.header-menu .links li:before,
.header-menu .links li:after {
  /* Hides arrows intended for action links in body */
  display: none;
}

// ============= menu-dropdown
/**
 * @file
 * Responsive menu styles
 */
.menu-dropdown {
  position: relative;
}

.js .menu-dropdown li,
.js .menu-dropdown a.has-submenu.highlighted {
  float: none;
  border-bottom: 1px solid #bbbbbb;
}

.js .menu-dropdown li.last {
  border-bottom: 0;
}
.js .menu-dropdown > li.last {
  padding-bottom: 1px;
}

.js .menu-dropdown ul li a {
  padding-left: 3em;
}

.js .menu-dropdown ul li li a {
  padding-left: 5em;
}

.js .menu-dropdown li.has-children > a {
  /* make room for the toggle button (sub indicator) */
  padding-right: 3.625rem;
}

.menu-dropdown a span.sub-arrow {
  position: absolute;
  top: 1rem;
  margin-top: -1.0625rem;
  left: auto;
  right: 0;
  width: 3.5rem;
  height: 3.5rem;
  overflow: hidden;
  //border: 1px solid #bbbbbb;
  //background: #333333;
  font: normal 1.384615rem/2.125rem monospace;
  text-align: center;
  text-shadow: none;
}

.menu-dropdown span.sub-arrow:before {
  display: block;
  content: '+';
  margin-top: 0.5625rem;
}

.menu-dropdown a.highlighted span.sub-arrow:before {
  display: block;
  content: '–';
}

@media (min-width: 34em) {
  .menu-dropdown a span.sub-arrow {
    width: 3.625rem;
    height: 3.625rem;
  }
}

@media (min-width: 48em) {
  /**
   * Switch to desktop layout
   * These transform the menu tree from
   * collapsible to desktop (navbar + dropdowns)
   */

  /* Begin section that shouldn't be edited */
  .js .menu-dropdown ul {
    position: absolute;
    width: 12rem;
    top: 1em;
    display: none;
  }

  .js .menu-dropdown li,
  .js .menu-dropdown a.has-submenu.highlighted {
    border-bottom: none;
  }

  .js .menu-dropdown ul li a,
  .js .menu-dropdown ul li li a{
    padding-left: 1.25rem;
  }

  .js .menu-dropdown > li {
    float: left;
  }

  [dir="rtl"] .menu-dropdown li {
    float: right;
  }

  .menu-dropdown ul li,
  [dir="rtl"] .menu-dropdown ul li {
    float: none;
  }

  .menu-dropdown a {
    white-space: nowrap;
  }

  .menu-dropdown ul a {
    white-space: normal;
  }
  /* END Rules that should not be edited */

  .js .menu-dropdown ul ul {
    border-left: 3px solid #20252e;
  }

  .js .menu-dropdown > li {
    border-bottom: 0;
  }
  .js .menu-dropdown > li.last {
    padding-bottom: 0;
  }

  .js .menu-dropdown li.has-children > a {
    padding-right: 1.75rem;
  }

  .menu-dropdown a span.sub-arrow {
    //top: 50%;
    margin-top: -0.125rem;
    //right: 0.75rem;
    right: 0.2rem;
    width: 0;
    height: 0;
    border-width: 0.25rem;
    border-style: solid dashed dashed dashed;
    //border-color: #ffffff transparent transparent transparent;
    background: transparent;
  }

  .menu-dropdown a.highlighted span.sub-arrow:before {
    display: none;
  }

  .menu-dropdown li {
    border-top: 0;
  }

  .menu-dropdown ul {
    padding: 0.3125rem 0;
  }

  .menu-dropdown ul .has-children > a {
    padding-right: 1.25rem;
  }

  .menu-dropdown ul a span.sub-arrow {
    right: 0.5rem;
    top: 33%;
    margin-top: -0.3125rem;
    border-width: 0.3125rem;
    border-style: dashed dashed dashed solid;
    border-color: transparent transparent transparent #ffffff;
  }

  .menu-dropdown span.scroll-up,
  .menu-dropdown span.scroll-down {
    position: absolute;
    display: none;
    visibility: hidden;
    overflow: hidden;
    height: 1.25rem;
  }

  .menu-dropdown span.scroll-up-arrow,
  .menu-dropdown span.scroll-down-arrow {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -0.375rem;
    width: 0;
    height: 0;
    overflow: hidden;
    border-width: 0.375rem;
    border-style: dashed dashed solid dashed;
    border-color: transparent transparent #ffffff transparent;
  }

  .menu-dropdown span.scroll-down-arrow {
    top: 0.5rem;
    border-style: solid dashed dashed dashed;
    border-color: #ffffff transparent transparent transparent;
  }

  [dir="rtl"] .menu-dropdown li.has-children > a {
    padding-right: 0.75rem;
    padding-left: 1.5rem;
  }

  [dir="rtl"] .menu-dropdown span.sub-arrow {
    right: auto;
    left: 0.75rem;
  }

  [dir="rtl"] .menu-dropdown > li > ul:before {
    left: auto;
    right: 1.875rem;
  }

  [dir="rtl"] .menu-dropdown > li > ul:after {
    left: auto;
    right: 1.9375rem;
  }

  [dir="rtl"] .menu-dropdown li.has-children > a {
    padding: 0.625rem 1.25rem;
  }

  [dir="rtl"] .menu-dropdown span.sub-arrow {
    right: auto;
    left: 0.5rem;
    border-style: dashed solid dashed dashed;
    border-color: transparent #ffffff transparent transparent;
  }
}



//dribling menu
.js .menu-dropdown a,
.js .menu-dropdown a:hover,
.js .menu-dropdown a:focus,
.js .menu-dropdown a:active {
  line-height: 1.25rem;
}

// <li> indentation
@media (max-width: 768px) {
  ul.menu-dropdown.menu.menu-style-processed.sm.menu-toggles-processed > li > ul > li,
  ul.menu-dropdown.menu.menu-style-processed.sm.menu-toggles-processed > li > ul > li > ul > li {
    margin-left: 2rem;
  }
}
ul.menu-dropdown.menu.menu-style-processed.sm.menu-toggles-processed > li a{

  &.active.active-trail{
    color:$teal;
  }
  &.active-trail{
    color:$teal;
  }
  color:$purple;
  padding: 1rem;
}

.l-sidebar .block-system-main-menu ul.menu-tree li.leaf{
  list-style: none;
  text-decoration: none;
}

.l-sidebar .block-system-main-menu ul.menu-tree > li a,
.l-sidebar .block-system-main-menu ul.menu-tree > li >ul>li a{
  color:$teal;
  &.active.active-trail{
    color:$purple;
  }
}


// Footer region styles.
.footer {
  margin-top: 40px;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;


  .footer-top {
    padding: 42px 0 35px;
    background-color: $gray-light;

    .block-menu {
      float: right;
      margin-left: 3.75%;
      @media (min-width: $screen-lg-min) {
        &:first-child {
          margin-right: 1%;
        }
      }

      @media (min-width: $screen-md-min) {
        width: auto;
        padding: 0;
      }

      @media (max-width: $screen-md-max) {
        margin-left: 1.5%;
      }

      @media (max-width: $screen-sm-max) {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
      }

      .block-title {
        font-weight: 700;
        font-size: 14px;
        margin: 0 0 5px;
        text-transform: uppercase;
      }

      > ul {
        margin: 0;
        padding: 0;

        li a {
          font-size: 13px;
          line-height: 25px;
          color: $gray-darker;
        }
      }
    }


    .mission-text {
      width: 31%;
      @media (max-width: $screen-md-max) {
        width: 26%;
      }
      margin-top: 5px;
      p {
        color: $gray-darker;
        font-size: 26px;
        font-weight: 300;
        @media (max-width: $screen-md-max) {
          font-size: 24px;
        }
      }
    }
  }

  .footer-bottom {
    background-color: $gray-darker;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $gray-light;

    .block {
      padding: 10px 0;
    }

    .contact-info {
      padding-left: 45px;

      @media (max-width: $screen-md-max) {
        padding: 0 30px 0;
      }
    }

    .telephone-info {
      text-align: center;
      padding-right: 35px;

      @media (max-width: $screen-md-max) {
        text-align: left;
        padding-right: 0;
        width: auto;
      }
    }

    .footer-logo {
      padding: 0;
      text-align: left;
      width: 175px;
      img {
        width: 100%;
        height: auto;
      }
    }

    .social-links {
      text-align: right;

      @media (max-width: $screen-xs-max) {
        text-align: center;
      }
      -webkit-transform: translate3d(0, 0, 0);
      a {
        font-size: 0;
        margin-left: 5px;
        -webkit-transform: translate3d(0, 0, 0);
        color: $gray-light;

        &:before {
          font-size: 19px;
        }

        &.icon:before {
          font-size: 22px;
        }

        &.glyphicon-earphone {
          -webkit-transform: rotateY(180deg);
                  transform: rotateY(180deg);
        }

        &.glyphicon {
          top: -2px;
        }

        &:hover {
          color: lighten($gray-light, 10);
        }
      }
    }
  }

  .footer-top,
  .footer-bottom {
    .region {
      @media (max-width: $screen-sm-max) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

.block-footer-item a{
  background-color: $teal;
  color: white;
}

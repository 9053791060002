// Content region styles.

a {
  color:$teal;
  text-decoration: none;
  &:hover{
    color:$purple;
  }
}
.menu-tree li.leaf{
  list-style: none;
  & a{
    text-decoration: none;
    color:$teal;
  }
}

.nav-tabs .nav-link{
  color:$teal;
  &.active{
    color:$purple;
  }
}

h1,
.h1,
h1.page-header {
  color: $purple;
  padding: 11px 28px;
  min-height: 50px;
}
//
//h2,
//.h2,
//h3,
//.h3,
//h4,
//.h4,
//h5,
//.h5 {
//  line-height: 1.2;
//}

.btn {
  @include border-top-radius(20px);
  // @include border-right-radius(20px);
  @include border-bottom-radius(20px);
  // @include border-left-radius(20px);

  &:hover {
    background-color: $gray;
    border-color: $gray;
  }
}

.secondary-page-images,
h1.page-header {
  margin-left: -25px;
  margin-right: -20px;

  body.front & {
    display: none;
  }
}

h2,
.h2 {
  color: $teal;
}

//HRIF block


  .block-menu-menu-hrif-data ul.menu-tree {
    display: flex;
    justify-content: space-between;
    padding: 0;
    gap: 5px;
    & li {
      display: inline-block;
      border: 2px solid $teal;
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
      font-family: 'Lato', Helvetica Neue, Helvetica, Arial, sans-serif;
      padding: 0;
      & :hover{
        background-color: $teal;
      }

      & a{
        display:block;
        line-height: 42px;
        padding: 0 1.5em;
        &:hover {
          color: white;
        }
      }
    }
  }

@media (max-width: 576px){
  .block-menu-menu-hrif-data ul.menu-tree {
    flex-direction: column;
    padding: 0;
    gap: 5px;
  }
}
.block-menu-menu-hrif-data {
  padding: 20px 0;
}

